// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

import { shallowRef, defineAsyncComponent } from 'vue'
export default {
  name: 'nsp',
  title: 'label.network.service.providers',
  icon: 'compass-outlined',
  docHelp: 'adminguide/networking.html#network-service-providers',
  hidden: true,
  permission: ['listNetworkServiceProviders'],
  columns: ['name', 'state', 'physicalnetworkid'],
  details: ['name', 'state', 'servicelist', 'canenableindividualservice', 'physicalnetworkid'],
  tabs: [{
    name: 'details',
    component: shallowRef(defineAsyncComponent(() => import('@/components/view/DetailsTab.vue')))
  }],
  actions: [
    {
      api: 'updateNetworkServiceProvider',
      icon: 'stop-outlined',
      label: 'label.disable.provider',
      message: 'message.confirm.disable.provider',
      dataView: true,
      args: ['state'],
      show: (record) => { return record.state === 'Enabled' },
      mapping: {
        state: {
          value: (record) => { return 'Disabled' }
        }
      }
    },
    {
      api: 'updateNetworkServiceProvider',
      icon: 'right-circle-outlined',
      label: 'label.enable.provider',
      message: 'message.confirm.enable.provider',
      dataView: true,
      args: ['state'],
      show: (record) => { return record.state === 'Disabled' },
      mapping: {
        state: {
          value: (record) => { return 'Enabled' }
        }
      }
    }
  ]
}
